import axios from '@/common/axios'
import qs from 'qs'

export function addCultureLedger (data) {
  return axios({
    method: 'post',
    url: '/storage/cultureLedger/add',
    data: qs.stringify(data)
  })
}

export function deleteCultureLedger (id) {
  return axios({
    method: 'delete',
    url: '/storage/cultureLedger/delete/' + id
  })
}

export function updateCultureLedger (data) {
  return axios({
    method: 'put',
    url: '/storage/cultureLedger/update',
    data: qs.stringify(data)
  })
}

export function selectCultureLedgerInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/cultureLedger/info/' + id
  })
}

export function selectCultureLedgerList (query) {
  return axios({
    method: 'get',
    url: '/storage/cultureLedger/list',
    params: query
  })
}
