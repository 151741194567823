<template>
  <div id="cultureLedger">
    <el-dialog
      :title="cultureLedgerFormTitle"
      width="680px"
      :visible.sync="cultureLedgerDialogVisible"
      :close-on-click-modal="false"
      @close="cultureLedgerDialogClose"
    >
      <el-form
        ref="cultureLedgerFormRef"
        :model="cultureLedgerForm"
        :rules="cultureLedgerFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="购入日期" prop="purchaseDate">
              <el-date-picker v-model="cultureLedgerForm.purchaseDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="名称" prop="name">
              <el-input v-model="cultureLedgerForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNum">
              <el-input v-model="cultureLedgerForm.batchNum" placeholder="请输入领用量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="cultureLedgerForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="cultureLedgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cultureLedgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cultureLedgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cultureLedgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column label="购入日期">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseDate">{{ scope.row.purchaseDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="batchNum" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cultureLedgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCultureLedger, deleteCultureLedger, updateCultureLedger, selectCultureLedgerInfo, selectCultureLedgerList } from '@/api/storage/cultureLedger'

export default {
  data () {
    return {
      cultureLedgerDialogVisible: false,
      cultureLedgerFormTitle: '',
      cultureLedgerForm: {
        id: '',
        purchaseDate: '',
        name: '',
        batchNum: '',
        quantity: '',
        remarks: ''
      },
      cultureLedgerFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      cultureLedgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      }
    }
  },
  created () {
    selectCultureLedgerList(this.searchForm).then(res => {
      this.cultureLedgerPage = res
    })
  },
  methods: {
    cultureLedgerDialogClose () {
      this.$refs.cultureLedgerFormRef.resetFields()
    },
    cultureLedgerFormSubmit () {
      if (this.cultureLedgerFormTitle === '培养基总帐详情') {
        this.cultureLedgerDialogVisible = false
        return
      }
      this.$refs.cultureLedgerFormRef.validate(async valid => {
        if (valid) {
          if (this.cultureLedgerFormTitle === '新增培养基总帐') {
            await addCultureLedger(this.cultureLedgerForm)
          } else if (this.cultureLedgerFormTitle === '修改培养基总帐') {
            await updateCultureLedger(this.cultureLedgerForm)
          }
          this.cultureLedgerPage = await selectCultureLedgerList(this.searchForm)
          this.cultureLedgerDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cultureLedgerFormTitle = '新增培养基总帐'
      this.cultureLedgerDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCultureLedger(row.id)
        if (this.cultureLedgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cultureLedgerPage = await selectCultureLedgerList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cultureLedgerFormTitle = '修改培养基总帐'
      this.cultureLedgerDialogVisible = true
      this.selectCultureLedgerInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cultureLedgerFormTitle = '培养基总帐详情'
      this.cultureLedgerDialogVisible = true
      this.selectCultureLedgerInfoById(row.id)
    },
    selectCultureLedgerInfoById (id) {
      selectCultureLedgerInfo(id).then(res => {
        this.cultureLedgerForm.id = res.id
        this.cultureLedgerForm.purchaseDate = res.purchaseDate
        this.cultureLedgerForm.name = res.name
        this.cultureLedgerForm.batchNum = res.batchNum
        this.cultureLedgerForm.quantity = res.quantity
        this.cultureLedgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCultureLedgerList(this.searchForm).then(res => {
        this.cultureLedgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCultureLedgerList(this.searchForm).then(res => {
        this.cultureLedgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCultureLedgerList(this.searchForm).then(res => {
        this.cultureLedgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
